import { atom } from 'recoil';

export type OverlayType =
    | 'contact'
    | 'extend'
    | 'noExtend'
    | 'header-message'
    | 'checkout-message'
    | 'countdown';

export enum ContactFormSubject {
    EXTEND = 'extend',
    CANCEL = 'cancel',
}

export interface IContactFormState {
    firstName?: string;
    lastName?: string;
    email?: string;
    orderNumber?: string;
    subject?: ContactFormSubject;
    message?: string;
    extendSepa?: boolean;
}

export const contactFormState = atom<IContactFormState | null>({
    key: 'contactForm',
    default: null,
});

export const overlayState = atom<OverlayType | false>({
    key: 'overlay',
    default: false,
});
