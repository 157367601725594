import css from 'classnames';
import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Header from '../header/Header';
import styles from './Maintenance.module.scss';

const Maintenance: FunctionComponent = () => {
    const { t } = useTranslation('common');

    return (
        <>
            <Header hideLogin hideAccount />

            <main style={{ position: 'relative' }}>
                <img
                    className={styles.maintenance__background}
                    alt="Student Desk"
                    src="/stage-bg.jpg"
                    aria-hidden
                />
                <div className={css(styles.maintenance__wrapper, 'o-content')}>
                    <div className={styles.maintenance}>
                        <div className={styles.maintenance__content}>
                            <strong>{t('maintenance.title')}</strong>
                            <p>{t('maintenance.description')}</p>
                            <p>{t('maintenance.descriptionLine2')}</p>
                            <p>{t('maintenance.videoTitle')}</p>
                            <div className={styles.maintenance__video}>
                                <iframe
                                    width="560"
                                    height="315"
                                    src={`https://www.youtube-nocookie.com/embed/xxIzyOhnKRY?autoplay=1&modestbranding=1&controls=1&loop=1&playlist=xxIzyOhnKRY,IzC5w26cKLY,3I6KL8UBaoE,zBQHAEzmOqc,hsmEoAuGPSQ,1Mph6yhlcxg,jCjbMX5_w80,TvQ7GCssOjQ,p0S-WQJ0J3E,jbLudG2B9TI&playsinline=1showinfo=0&rel=0`}
                                    title="STUWO Dorm Tours"
                                    style={{
                                        border: 'none',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                />
                            </div>
                            <p>{t('maintenance.outro')}</p>
                            <strong>{t('maintenance.team')}</strong>
                            <small>
                                <Trans i18nKey="common:maintenance.ps">
                                    placeholder
                                    <a href="mailto:info@stuwo.at">
                                        info@stuwo.at
                                    </a>
                                    placeholder
                                </Trans>
                            </small>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Maintenance;
