import { fetcher, put } from '.';
import { IFetcherResponse, ILogin } from '../models';

export const initUser = async (dto: {
    personNo: string;
    lang: string;
}): Promise<IFetcherResponse<any>> => {
    if (!dto) {
        Promise.reject('no data');
    }

    return put(`${process.env.REACT_APP_BOOKING_API_URL}/user/init`, dto);
};

export const changePassword = async (
    dto: any,
): Promise<IFetcherResponse<any>> => {
    if (!dto) {
        Promise.reject('no data');
    }

    return put(`${process.env.REACT_APP_BOOKING_API_URL}/user/password`, dto);
};

export const login = async (dto: ILogin): Promise<IFetcherResponse<any>> => {
    if (!dto) {
        Promise.reject('no data');
    }

    try {
        return await put(
            `${process.env.REACT_APP_BOOKING_API_URL}/user/login`,
            dto,
        );
    } catch (error: any) {
        if (error?.message?.includes('Unauthorized')) {
            return Promise.reject('Unauthorized');
        }
        throw error;
    }
};

export const logout = async (
    sessionId: string,
): Promise<IFetcherResponse<any>> => {
    if (!sessionId) {
        Promise.reject('no data');
    }

    return put(`${process.env.REACT_APP_BOOKING_API_URL}/user/logout`, {
        sessionId,
    });
};

export const resetPassword = async (
    username: string,
): Promise<IFetcherResponse<any>> => {
    if (!username) {
        Promise.reject('no data');
    }

    return put(`${process.env.REACT_APP_BOOKING_API_URL}/user/reset`, {
        username,
    });
};

export const fetchUserStatus = async (
    sessionId: string,
): Promise<IFetcherResponse<any>> => {
    if (!sessionId) {
        Promise.reject('no sessionId');
    }

    return fetcher(
        `${process.env.REACT_APP_BOOKING_API_URL}/user/status?sessionId=${sessionId}`,
    );
};

export const fetchUserExists = async (
    username: string,
): Promise<IFetcherResponse<any>> => {
    if (!username) {
        Promise.reject('no sessionId');
    }

    return fetcher(
        `${process.env.REACT_APP_BOOKING_API_URL}/user/exists?username=${username}`,
    );
};

export const extendContract = async (
    sessionId: string,
): Promise<IFetcherResponse<any>> => {
    if (!sessionId) {
        Promise.reject('no sessionId');
    }

    return put(`${process.env.REACT_APP_BOOKING_API_URL}/user/extendContract`, {
        sessionId,
    });
};

export const noExtendContract = async (
    sessionId: string,
): Promise<IFetcherResponse<any>> => {
    if (!sessionId) {
        Promise.reject('no sessionId');
    }

    return put(`${process.env.REACT_APP_BOOKING_API_URL}/user/noExtension`, {
        sessionId,
    });
};
